import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import SectionHeading from "../../components/common/SectionHeading";
import Fade from "react-reveal";
import Cta from "../../components/Cta";
import Button from "../../components/common/Button";
import { FiMap, MdOutlinePeopleAlt, MdSensors } from "react-icons/all";
import { graphql } from "gatsby";
import Layout from "../../layouts";

const connectedcity = () => {
  return (
    <Layout title="Connected City">
      <article className="py-36 container mx-auto px-6">
        <Fade bottom>
          <section className="flex flex-col md:flex-row justify-between items-center gap-8">
            <div>
              <SectionHeading>Connected City</SectionHeading>
              <p className="prose mt-4 text-text-primary dark:text-text-primary-dark text-justify">
                Connected City aims to create an urban ecosystem by providing an
                interactive platform for the residents in order to make their
                city a safe and welcoming place to live.
              </p>
              <div className="flex gap-6 mt-8">
                <Button
                  as={"a"}
                  href="https://connectedcity.pl"
                  target="_blank"
                  rel="external"
                >
                  Get started
                </Button>
              </div>
            </div>
            <StaticImage
              objectFit="cover"
              src="../../static/images/09.jpg"
              className="product-tile object-center transform group-hover:scale-105 transition duration-200 w-full md:w-1/2 rounded-xl"
            />
          </section>
        </Fade>
        <Fade bottom>
          <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-12 xl:gap-16 mt-20">
            <div className="flex flex-col items-center p-8 shadow-md rounded-3xl bg-white dark:bg-dark-accent">
              <div className="w-16 h-16 md:h-24 md:w-24 flex justify-center items-center text-yellow-400 mb-2 sm:mb-4">
                <FiMap size={100} color="currentColor" />
              </div>

              <h3 className="text-lg md:text-xl font-semibold text-center mb-2 dark:text-white">
                Threat map
              </h3>
              <p className="text-text-primary dark:text-text-primary-dark text-center mb-2">
                One map with all necessary information.
              </p>
            </div>

            <div className="flex flex-col items-center p-8 shadow-md rounded-3xl bg-white dark:bg-dark-accent">
              <div className="w-16 h-16 md:h-24 md:w-24 flex justify-center items-center text-yellow-400 mb-2 sm:mb-4">
                <MdOutlinePeopleAlt size={100} color="currentColor" />
              </div>

              <h3 className="text-lg md:text-xl font-semibold text-center mb-2 dark:text-white">
                Threat reporting
              </h3>
              <p className="text-text-primary dark:text-text-primary-dark text-center mb-2">
                Residents can contribute by submitting their own reports.
              </p>
            </div>

            <div className="flex flex-col items-center p-8 shadow-md rounded-3xl bg-white dark:bg-dark-accent">
              <div className="w-16 h-16 md:h-24 md:w-24 flex justify-center items-center text-yellow-400 mb-2 sm:mb-4">
                <MdSensors size={100} color="currentColor" />
              </div>

              <h3 className="text-lg md:text-xl font-semibold text-center mb-2 dark:text-white">
                Sensor data
              </h3>
              <p className="text-text-primary dark:text-text-primary-dark text-center mb-2">
                A set of sensors spread across the city makes advanced data
                analysis possible.
              </p>
            </div>
          </div>
        </Fade>
      </article>
    </Layout>
  );
};

export default connectedcity;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
